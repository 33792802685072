import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo('en-US');

export const formatDateTime = (date: string | Date) => {
  return Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  }).format(new Date(date));
};

const toLocal = (date: string | Date) => {
  let dateLocal = new Date(date);
  let newDate = new Date(dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000);
  return newDate;
};

export const formatDate = (date: string | Date) => {
  return Intl.DateTimeFormat('en-US', {
    dateStyle: 'medium',
  }).format(new Date(date));
};

export const toLocalTimeAgo = (date: string | Date) => {
  return timeAgo.format(toLocal(date));
};

export const toTimeAgo = (date: string | Date) => {
  return timeAgo.format(new Date(date));
};
